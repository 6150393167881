<template>
  <div>
    <div :id="id">
      <div class="view-receipt">
        <div class="A5">
          <div class="header px-4 pt-3 pb-0">
            <div class="row">
              <div class="col-12">
                <h3 class="receipt-title">{{ t("title") }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="logo col-auto px-0">
                <Logo
                  class="pl-3"
                  :src="logo"
                  :alt="clinicName"
                  :callback="loadLogoCallback"
                />
              </div>
              <div class="col pr-0">
                <p class="address">
                  <strong class="clinic-name">
                    {{ clinicName }}
                  </strong>
                  <br />
                  <span v-if="!isEmpty(clinicAddress)" class="clinic-address">
                    {{ clinicAddress }}
                  </span>
                  <br />
                  <span
                    v-if="!isEmpty(clinicTelephone)"
                    class="clinic-telephone"
                    >{{ t("tel") }}: {{ clinicTelephone }}</span
                  >
                </p>
              </div>
              <div class="col-3 document-info">
                <div class="text-center mb-2">
                  <h5 class="receipt-type">{{ receiptType }}</h5>
                </div>
                <p class="text-right">
                  <span>{{ t("receipt-no") }}:</span>
                  <span class="receipt-no">{{ receipt.receiptNo }}</span>
                  <br />
                  <span>{{ t("date") }}:</span>
                  <span class="date">{{ receipt.createDate }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="body px-4">
            <table class="w-100">
              <thead>
                <tr>
                  <td>
                    <div class="header-space">&nbsp;</div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row patient-info">
                      <div class="col-7 text-left">
                        <p>
                          {{ t("patient-name") }}:
                          <strong class="pr-2">{{ patientName }}</strong>
                          <strong>{{ t("dn") }}: {{ patient.dn }}</strong>
                        </p>
                        <p>
                          <span>{{ t("address") }}: {{ patientAddress }}</span>
                        </p>
                        <!-- Temporarily delete patient contacts
                        <p>
                          <span>ที่อยู่: {{ patientAddress }}</span>
                        </p>
                        <p>
                          <span class="pr-2"
                            >โทร: {{ formatPhoneNumber(patient.mobile) }}</span
                          >
                          <span v-if="patient.email"
                            >อีเมล: {{ patient.email }}</span
                          >
                        </p>
                        -->
                      </div>
                      <div
                        v-if="!isEmpty(nextVisit) && !isEmpty(nextVisit.start)"
                        class="col text-right"
                      >
                        <p>
                          <strong>{{ t("next-app") }}</strong>
                          <br />
                          <span class="next-visit-date">
                            {{ nextVisitDateTime }}
                          </span>
                          <span
                            class="pl-2"
                            v-if="!isEmpty(nextVisitTreatmentTitle)"
                          >
                            <span>{{ t("treatment") }}:</span>
                            <span class="next-visit-treatment">
                              {{ nextVisitTreatmentTitle }}
                            </span>
                          </span>
                          <br />
                          <span v-if="!isEmpty(nextVisitDoctorName)">
                            <span>{{ t("dentist") }}:</span>
                            <span class="next-visit-treatment">
                              {{ nextVisitDoctorName }}
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="row payment-table">
                      <div class="col">
                        <b-table
                          table-class="table-black"
                          :bordered="false"
                          foot-variant="danger"
                          :items="receipt.items"
                          :fields="visibleFields"
                        >
                          <template #cell(amount)="row">
                            <div>{{ row.value }}</div>
                            <div v-if="row.item.remained">
                              ({{ t("overdue") }} {{ row.item.remained }})
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="row payment-summary">
                      <div class="col-auto pr-0">
                        <strong>{{ t("paid-by") }}</strong>
                      </div>
                      <div class="col">
                        <div
                          v-for="category in receipt.displayAmount"
                          :key="category.id"
                          class="row"
                        >
                          <div class="col-6">
                            {{ getCateName(category, lang) }}:
                          </div>
                          <div class="col-6 text-right">
                            {{ formatCurrency(category.amount) }}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col-6">{{ t("total-price") }}:</div>
                          <div class="col-6 text-right">
                            {{ formatCurrency(receipt.amount) }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">{{ t("pay-today") }}:</div>
                          <div class="col-6 text-right">
                            {{ formatCurrency(receipt.paid) }}
                          </div>
                          <div class="col-12 text-right">
                            ( {{ formatWordCurrency(receipt.paid, lang) }} )
                          </div>
                        </div>
                        <div v-show="receipt.overdue" class="row text-danger">
                          <div class="col-6">{{ t("overdue") }}:</div>
                          <div class="col-6 text-right">
                            {{ formatCurrency(receipt.overdue) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div class="footer-space">&nbsp;</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="footer px-4 pt-0 pb-3">
            <div class="row">
              <div class="col-4">
                <p class="text-center mt-5 pt-2 underline-black">
                  {{ t("service-recipient") }}
                </p>
              </div>
              <div class="col-4 offset-4">
                <p class="text-center mt-5 pt-2 underline-black">
                  {{ t("financial-officer") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import moment from "moment";
import numeral from "numeral";
import THBText from "thai-baht-text";
import USDText from "dollars-to-words";
import { cloneDeep, trim, get, isEmpty, uniqueId } from "lodash";
import { mapActions, mapGetters } from "vuex";
import Logo from "@/components/Logo";
import Loading from "@/components/Loading";
import { eventBus } from "@/main";
import "@/theme/ReceiptPreview.scss";
import $ from "jquery";

import Dialog from "@/components/modal/Dialog";

export default {
  name: "ReceiptView",
  components: {
    Loading,
    Logo,
    Dialog,
  },
  props: {
    mode: String,
    lang: String,
    containerTarget: String,
    afterPrint: Function,
    createReceiptSuccess: Function,
    patientData: Object,
    receiptData: Object,
    displayKeys: Array,
  },
  data() {
    return {
      id: uniqueId("receipt-"),
      isLoading: false,
      isCreateReceipt: false,
      mediaPrint: null,
      printCount: 0,
      logo: null,
      nextVisit: {},
      patient: {},
      receipt: {
        receiptNo: "",
        amount: 0,
        paid: 0,
        overdue: 0,
        displayAmount: [],
        items: [],
        fields: [
          {
            key: "name",
            label: this.t("list"),
            thClass: ["text-center"],
            visible: true,
          },
          {
            key: "doctor",
            label: this.t("doctor"),
            thStyle: { width: "200px" },
            thClass: ["text-center"],
            visible: true,
          },
          {
            key: "quantity",
            label: this.t("quantity"),
            thStyle: { width: "50px" },
            thClass: ["text-center"],
            tdClass: ["text-right"],
            visible: true,
          },
          {
            key: "price",
            label: this.t("price"),
            thStyle: { width: "100px" },
            thClass: ["text-center"],
            tdClass: ["text-right"],
            visible: true,
          },
          {
            key: "finalDiscount",
            label: this.t("discount"),
            thStyle: { width: "100px" },
            thClass: ["text-center"],
            tdClass: ["text-right"],
            visible: true,
          },
          {
            key: "amount",
            label: this.t("net-price"),
            thStyle: { width: "100px" },
            thClass: ["text-center"],
            tdClass: ["text-right"],
            visible: true,
          },
        ],
      },
    };
  },
  created() {
    if (window.matchMedia) {
      this.mediaPrint = window.matchMedia("print");
    }
    this.patient = this.patientData;
    this.receipt.receiptNo = get(this.receiptData, "receiptNo", "-");
    this.receipt.createDate = this.getDateTime(
      get(this.receiptData, "creationDt", moment().format()),
      this.lang
    );
    this.receipt.amount = this.receiptData.amount;
    this.receipt.paid = this.receiptData.paid;
    this.receipt.overdue = this.receiptData.overdue;
    this.receipt.displayAmount = this.receiptData.displayAmount; // Display Payment Methods
    this.receipt.items = cloneDeep(
      this.receiptData.receiptItems || // receiptItems จะส่งมาใน payment หรือ api reprint จาก backend node ตั่วใหม่
        this.receiptData.item || // item จะถูกส่งมาจาก api reprint ที่ถูกสร้างจากตัวเก่า
        []
    ).map((item) => {
      delete item._rowVariant;
      if (item.quantity == null) {
        item.quantity = "";
      }
      if (item.treatmentItem) {
        // api reprint ตัวใหม่ จะส่งรายละเอียด nest อยู่ใน treatmentItem
        item.name =
          this.lang == "th"
            ? item.treatmentItem.name
            : item.treatmentItem.nameEn || item.treatmentItem.name;
        const { fullNameEn, fullName } = this.getUserListIncludeDeletedBy(
          item.treatmentItem.actionUid,
          "uid"
        );
        item.doctor = this.lang == "th" ? fullName : fullNameEn || fullName;
      } else {
        item.name = this.lang == "th" ? item.name : item.nameEn || item.name;
        item.doctor =
          this.lang == "th" ? item.doctor : item.doctorEn || item.doctor;
      }

      return {
        ...item,
        // name: treatmentName,
        // doctor: doctorName,
        price: this.formatCurrency(item.price),
        finalDiscount: this.formatCurrency(item.finalDiscount),
        amount: this.formatCurrency(item.amount),
      };
    });

    if (this.mode == "create") {
      this.loadNextVisit();
    } else {
      this.nextVisit = get(this.receiptData, "nextVisit", {});
    }
    this.logo = this.viewFile(this.getBranchInfo.clinic.logo);
  },
  mounted() {
    this.updatePrint();
  },
  beforeDestroy() {
    $(this.containerTarget).empty();
    $("body").removeClass("print-content-modal");
    this.enabledMediaQueryListener(false);
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getTreatmentList: "moduleAppointment/getTreatmentList",
      viewFile: "moduleContent/viewFile",
      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",
    }),
    visibleFields() {
      let permanentFields = ["name", "amount"];
      if (this.isDiscounted)
        permanentFields = permanentFields.concat(["price", "finalDiscount"]);
      return this.receipt.fields.filter((field) => {
        return this.displayKeys?.concat(permanentFields).includes(field.key);
      });
    },
    isDiscounted() {
      return this.receipt.items.filter((i) => i.finalDiscount > 0).length > 0;
    },
    receiptType() {
      return (this.mode == "create" &&
        this.isCreateReceipt &&
        this.printCount == 0) ||
        this.mode === "original"
        ? this.t("original")
        : this.mode == "cancel"
        ? this.t("cancel")
        : this.mode == "copy" || this.printCount > 0
        ? this.t("copy")
        : this.t("example");
    },
    branchName() {
      const branchName = get(this.getBranchInfo, "name") || "";
      return this.lang == "th"
        ? branchName
        : get(this.getBranchInfo, "nameEn") || branchName;
    },
    clinicName() {
      const clinicName = get(this.getBranchInfo, "clinic.name") || "";
      return this.lang == "th"
        ? clinicName
        : get(this.getBranchInfo, "clinic.nameEn") || clinicName;
    },
    clinicAddress() {
      const clinicAddress = trim(get(this.getBranchInfo, "address")) || "";
      return this.lang == "th"
        ? clinicAddress
        : trim(get(this.getBranchInfo, "addressEn")) || clinicAddress;
    },
    clinicTelephone() {
      return get(this.getBranchInfo, "tel") || "";
    },
    patientName() {
      return this.getPatientName(this.patient, this.lang);
    },
    patientAddress() {
      const { address, subDistrict, district, province, postcode } =
        this.patient;

      const text = [address, subDistrict, district, province, postcode]
        .filter(Boolean)
        .join(" ");

      return text || "-";
    },
    currentDate() {
      return this.getDate(moment().format(), this.lang);
    },
    currentTime() {
      return this.getTime(moment().format(), this.lang);
    },
    currentDateTime() {
      return this.getDateTime(moment().format(), this.lang);
    },
    nextVisitDateTime() {
      return !isEmpty(this.nextVisit.start)
        ? this.getFullDateTime(this.nextVisit.start, this.lang)
        : "";
    },
    nextVisitTreatmentTitle() {
      return this.lang == "th"
        ? this.nextVisit.treatmentTitle
        : this.nextVisit.treatmentTitleEn || this.nextVisit.treatmentTitle;
    },
    nextVisitDoctorName() {
      return this.lang == "th"
        ? this.nextVisit.doctorName
        : this.nextVisit.doctorNameEn || this.nextVisit.doctorName;
    },
  },
  methods: {
    ...mapActions({
      createReceipt: "moduleReceipt/createReceipt",
      fetchNextVisitAppointmentByPatientId:
        "moduleAppointment/fetchNextVisitAppointmentByPatientId",
      fetchBranchByUrl: "moduleBranch/fetchBranchByUrl",
    }),
    t(path) {
      const trans = {
        th: {
          title: "ใบเสร็จรับเงิน",
          dn: "DN",
          original: "ต้นฉบับ",
          cancel: "ยกเลิก",
          copy: "สำเนา",
          example: "ตัวอย่าง",
          overdue: "ค้างชำระ",
          remain: "ค้าง",
          "pay-today": "ชำระวันนี้",
          "paid-by": "ชำระโดย",
          "total-price": "ราคารวมสุทธิ",
          dentist: "ทันตแพทย์",
          treatment: "การรักษา",
          "patient-name": "ชื่อ",
          date: "วันที่",
          "receipt-no": "เลขที่ใบเสร็จ",
          tel: "โทร",
          "next-app": "นัดหมายถัดไป",
          "service-recipient": "ผู้รับบริการ",
          "financial-officer": "เจ้าหน้าที่การเงิน",
          list: "รายการ",
          doctor: "แพทย์",
          quantity: "จำนวน",
          price: "ราคา",
          discount: "ส่วนลด",
          "net-price": "ราคาสุทธิ",
          address: "ที่อยู่",
        },
        en: {
          title: "RECEIPT",
          dn: "DN",
          original: "Original",
          cancel: "Cancel",
          copy: "Copy",
          example: "Example",
          overdue: "Overdue",
          remain: "remain",
          "pay-today": "Paid",
          "paid-by": "Paid By",
          "total-price": "Total Net Price",
          dentist: "Dentist",
          treatment: "Treatment",
          "patient-name": "Name",
          date: "Date",
          "receipt-no": "Receipt No.",
          tel: "Tel",
          "next-app": "Next Appointment",
          "service-recipient": "Service Recipient",
          "financial-officer": "Financial Officer",
          list: "Description",
          doctor: "Doctor",
          quantity: "Quantity",
          price: "Price",
          discount: "Discount",
          "net-price": "Net Price",
          address: "Address",
        },
      };
      return get(trans, `${this.lang || "th"}.${path}`) || path;
    },
    async print(isPaotang) {
      if (!this.isLoading) {
        if (this.mode == "create") {
          this.generateReceipt(() => {
            if (isPaotang) {
              this.afterPrint();
              return;
            }
            this.preparePrint();
          });
        } else {
          this.preparePrint();
        }
      } else {
        eventBus.$emit("alertToast", {
          message: "กำลังเตรียมพิมพ์ กรุณารอสักครู่",
          variant: "warning",
        });
      }
    },
    updatePrint() {
      $(this.containerTarget)
        .empty()
        .html($(`#${this.id} .view-receipt`).clone());
      $("body").addClass("print-content-modal");
    },
    preparePrint() {
      $(this.containerTarget)
        .empty()
        .html($(`#${this.id} .view-receipt`).clone());
      const logoImg = $(this.containerTarget).find(".logo img");
      if (!isEmpty(this.logo) && logoImg.length > 0) {
        const self = this;
        logoImg
          .on("load", function () {
            self.previewPrint();
          })
          .on("error", function () {
            self.previewPrint();
          });
      } else {
        this.previewPrint();
      }
    },
    previewPrint() {
      if (this.$device.browser.safari && this.printCount >= 1) {
        eventBus.$emit("alertSwal", {
          title: "การสั่งพิมพ์ใบเสร็จ",
          message: `ท่านสามารถใช้คำสั่ง "พิมพ์" หรือ "${
            this.$device.os.mac || this.$device.os.ios ? "Cmd" : "Ctrl"
          } + P" จากเบราว์เซอร์ได้โดยตรง หากการสั่งพิมพ์ใบเสร็จถูกปิดกั้น`,
          icon: "info",
          callback: (result) => {
            if (result.isConfirmed) {
              this.processPrint();
            }
          },
        });
      } else {
        this.processPrint();
      }
    },
    processPrint() {
      let pos = document.body.style.position;
      if (pos === "fixed") {
        document.body.style.position = "unset";
      }
      if (document.queryCommandSupported("print")) {
        if (!this.$device.browser.safari && this.mediaPrint) {
          this.enabledMediaQueryListener(true);
        }
        if (!document.execCommand("print")) {
          window.print();
        }
      }
      if (pos === "fixed") {
        document.body.style.position = "fixed";
      }
      this.printCount += 1;
    },
    enabledMediaQueryListener(isEnabled) {
      if (this.mediaPrint) {
        if (isEnabled) {
          this.mediaPrint.addEventListener("change", this.checkMediaQuery);
        } else {
          this.mediaPrint.removeEventListener("change", this.checkMediaQuery);
        }
      }
    },
    checkMediaQuery(e) {
      if (e.matches) {
        console.log("Before Print:", e.matches);
      } else {
        console.log("After Print:", e.matches);
        if (this.afterPrint) {
          this.afterPrint();
        }
        this.enabledMediaQueryListener(false);
      }
    },
    loadLogoCallback(src) {
      this.logo = src;
    },
    async generateReceipt(callback) {
      let data = this.formatReceiptData(this.receiptData);

      if (this.receipt.receiptNo == "-") {
        const clinicUrl = this.$route.params.clinicUrl;
        const branchUrl = this.$route.params.branchUrl;
        this.isLoading = true;
        this.createReceipt({
          data,
          // data: this.receiptData,
          clinicUrl,
          branchUrl,
        })
          .then(async (res) => {
            if (res.data === "APPT-IS-NOT-UPTO-DATE" && res.status === 200) {
              await this.$refs.Dialog.showAlertInfo(
                "มีการแก้ไขข้อมูลชำระเงิน",
                "กรุณาทำรายการชำระเงินใหม่",
                "warning"
              )
                .then(() => {
                  this.$bvModal.hide("PaymentReceiptPreviewModal");
                  this.$bvModal.hide("PaymentMethodModal");
                  this.$bvModal.hide("PaymentModal");
                })
                .finally(() => {
                  throw res.data;
                });
            }
            this.receipt.receiptNo = get(res, "data.receipt.receiptNo", "-");
            this.receipt.createDate = this.getDateTime(
              get(res, "data.creationDt"),
              "th"
            );
            eventBus.$emit("alertToast", {
              message: "บันทึกข้อมูลใบเสร็จสำเร็จ",
              variant: "success",
            });
            if (this.createReceiptSuccess) {
              this.isCreateReceipt = true;
              this.createReceiptSuccess();
            }
            this.$nextTick(() => {
              if (callback) {
                callback();
              }
            });
          })
          .catch((err) => {
            console.error("Error:", err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        callback();
      }
    },
    async loadNextVisit() {
      const clinicUrl = this.$route.params.clinicUrl;
      const branchUrl = this.$route.params.branchUrl;
      const patientId = this.patient.id;
      this.isLoading = true;

      this.fetchNextVisitAppointmentByPatientId({
        clinicUrl,
        branchUrl,
        patientId,
      })
        .then((res) => {
          this.nextVisit = res;
          this.$nextTick(() => {
            this.updatePrint();
          });
        })
        .catch((err) => {
          console.error("Error:", err);
          this.nextVisit = {};
        })
        .finally(() => {
          this.isLoading = false;
          this.receiptData.nextVisit = this.nextVisit;
        });
    },
    isEmpty(value) {
      return isEmpty(value);
    },
    formatCurrency: (value) => {
      return numeral(value).format("0,0.00");
    },
    formatWordCurrency: (value, language = "th") => {
      if (Number.isNaN(value)) return;
      if (language == "th") {
        return THBText(value) || "ศูนย์บาทถ้วน";
      } else {
        return USDText(value)
          .replace("dollar", "baht")
          .replace("cent", "satang")
          .toUpperCase();
      }
    },
    getCateName: (category, language = "th") => {
      const cateName = category.cateName;
      if (language == "th") {
        return cateName;
      } else {
        return category.cateNameEn || cateName;
      }
    },
    getPatientName: (patient, language = "th") => {
      const patientName = trim(
        `${trim(patient.titleNameTh)} ${patient.firstNameTh} ${
          patient.lastNameTh
        }`
      );
      if (language == "th") {
        return patientName;
      } else {
        return (
          trim(
            `${trim(patient.titleNameEn || patient.titleNameTh)} ${
              patient.firstNameEn || patient.firstNameTh
            } ${patient.lastNameEn || patient.lastNameTh}`
          ) || patientName
        );
      }
    },
    getDate: (dateTime, language = "th") => {
      moment.locale(language);
      const year =
        parseInt(moment(dateTime).format("YYYY")) +
        (language == "th" ? 543 : 0);
      const month = moment(dateTime).format("MMM");
      const date = moment(dateTime).format("D");
      return `${date} ${month} ${year.toString().substr(2)}`;
    },
    getTime: (dateTime, language = "th") => {
      moment.locale(language);
      const time = moment(dateTime).format("HH:mm");
      return `${time}`;
    },
    getDateTime: (dateTime, language = "th") => {
      moment.locale(language);
      const year =
        parseInt(moment(dateTime).format("YYYY")) +
        (language == "th" ? 543 : 0);
      const dateMonth = moment(dateTime).format("D MMM");
      const time = moment(dateTime).format("HH:mm");
      return `${dateMonth} ${year.toString().substr(2)} ${time}`;
    },
    getFullDateTime: (dateTime, language = "th") => {
      moment.locale(language);
      const year =
        parseInt(moment(dateTime).format("YYYY")) +
        (language == "th" ? 543 : 0);
      const dateMonth = moment(dateTime).format("ddd D MMM");
      const time = moment(dateTime).format("HH:mm");
      return `${dateMonth} ${year.toString().substr(2)} ${time}`;
    },
    formatPhoneNumber: (phone) => {
      if (phone) {
        return `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(
          6,
          4
        )}`;
      } else {
        return "-";
      }
    },
    isNumberEmpty: (value) => {
      if (value > 0 && !isNaN(value)) {
        return false;
      } else {
        return true;
      }
    },
    formatReceiptData(data) {
      return {
        appointmentId: parseInt(data.appointmentId),
        treatment: { id: data.treatmentId, modifyDt: data.treatmentModifyDate },
        receipt: { ...data },
        nextVisit: this.nextVisit,
        displayAmount: this.receiptData.displayAmount,
      };
    },
  },
};
</script>

<style></style>